import React, { useEffect } from 'react';
import '../../App.css';
import CorporateInfoPage from '../CorporateInfo_Page';


function CorporateInfo() {
 
    useEffect(() => {
        document.title = "Corporate Info - Elysium Plantation Berhad ";  
    }, []);

    return (
        <>
            <CorporateInfoPage />
        </>
    );
}

export default CorporateInfo;