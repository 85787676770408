import React, { useState, useEffect } from 'react'; 
import '../../App.css';
import './PhotoGallery.scss';
  
import Modal from 'react-bootstrap/Modal';
import { Image } from 'antd';


function GalleryModal(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            
            <figure className="gallery__thumb"  data-toggle="modal" onClick={handleShow}>
                <img src={props.imgsrc} alt="Elysium Plantation's Karak Location" className="gallery__image" />
            </figure>

             <Modal show={show} onHide={handleClose} className="gallery-modal" >
                <Modal.Body className="gallery-modal-body" >
                    <img src={props.imgsrc} alt="Elysium Plantation's Karak Location" />
                </Modal.Body>
            </Modal>
        </>
    );
}

function KarakGallery() {

    useEffect(() => {
        document.title = "Karak Gallery - Elysium Plantation Berhad ";  
    }, []);

    // karak
    const karak_1 = require("../../images/locations/Karak/Karak-06.jpg");
    const karak_2 = require("../../images/locations/Karak/Karak-07.jpg");
    const karak_3 = require("../../images/locations/Karak/Karak-03.jpg");
    const karak_4 = require("../../images/locations/Karak/Karak-04.jpg");
    const karak_5 = require("../../images/locations/Karak/Karak-05.jpg");
    const karak_6 = require("../../images/locations/Karak/Karak-01.jpg");
    const karak_7 = require("../../images/locations/Karak/Karak-02.jpg");

    return (

        <>
            <div className="wrap-gallery">

                <section className='wrap-gallery-hero'>
                    <div className='gallery-hero'> 
                        <div className='gallery-hero-heading'>
                            <h1>Karak</h1>
                            <h2>Photo Gallery</h2>
                        </div>
                    </div>
                </section>

                <div className="ld-divider-right mobile-divd"></div>

                <div className="gallery-container">
                    <div className="gallery-content-1"> 
                        {/* <div class="gallery-cont-item gallery">
                            <div class="gallery__column">
                                <GalleryModal
                                    imgsrc={karak_1}
                                />
                                <GalleryModal
                                    imgsrc={karak_2}
                                />
                            </div>
                            
                            <div class="gallery__column">
                                <GalleryModal
                                    imgsrc={karak_4}
                                />
                                <GalleryModal
                                    imgsrc={karak_5}
                                />
                            </div>
                            
                            <div class="gallery__column"> 
                                <GalleryModal
                                    imgsrc={karak_3}
                                />
                                <GalleryModal
                                    imgsrc={karak_6}
                                />
                            </div>
                            
                            <div class="gallery__column"> 
                                <GalleryModal
                                    imgsrc={karak_7}
                                />
                            </div>
                        </div> */}

                        <Image.PreviewGroup>
                            <Image width={300} height={200} src={karak_1} alt="Karak - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={karak_2} alt="Karak - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={karak_3} alt="Karak - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={karak_4} alt="Karak - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={karak_5} alt="Karak - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={karak_6} alt="Karak - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={karak_7} alt="Karak - Elysium Plantation Berhad" />
                        </Image.PreviewGroup>
                    </div> 

                </div>
                
            </div>
        </>
        
        
    );
}

export default KarakGallery;