import React, { useState } from 'react'; 
import '../App.css';
import './CompanyPolicies.scss';

import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';


function WhistleBlowerModal(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // carousel 
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <>
            <div className="compp-cont-item" type="button" data-toggle="modal" onClick={handleShow}>
                <h2>
                    {props.policy_title}
                </h2>
            </div>

             <Modal show={show} onHide={handleClose} className="compp-modal" >
                <Modal.Header closeButton>  </Modal.Header>
                <Modal.Body className="compp-modal-body" >
                    {/* <img className="compp-modal-img"
                        src={props.policy_img} 
                        alt={props.policy_imgalttext} 
                    /> */}
                    <Carousel  activeIndex={index} onSelect={handleSelect} interval={null}>
                        <Carousel.Item>
                            <img className="compp-modal-img"
                                src={props.policy_img_1} 
                                alt={props.policy_imgalttext_1} 
                            />
                        </Carousel.Item>

                        <Carousel.Item>
                            <img className="compp-modal-img"
                                src={props.policy_img_2} 
                                alt={props.policy_imgalttext_2} 
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="compp-modal-img"
                                src={props.policy_img_3} 
                                alt={props.policy_imgalttext_3} 
                            />
                        </Carousel.Item>
                    </Carousel>
                </Modal.Body>
            </Modal>
        </>
    );
}

function NoPolicyYet(props) {
    
    return (
        <>
            <div className="compp-cont-item" type="button">
                <h2>
                    {props.policy_title}
                </h2>
            </div>
        </>
    );
}

function CompanyPoliciesPage() {

    const whistle_1 = require("../images/BG_26.png");
    const whistle_2 = require("../images/BG_27.png");
    const whistle_3 = require("../images/BG_28.png");

    return (

        <>
            <div className="wrap-compp">

                <section className='wrap-hero comp-policy'>
                    <div className='hero-container'> 
                        <div className='hero-heading'>
                            <h1>Company Policies</h1>
                        </div>
                    </div>
                </section>

                <div className="ld-divider-right mobile-divd"></div>

                <div className="compp-container">
                    <section className="compp-content-1"> 
                        <WhistleBlowerModal
                            policy_title="Whistle Blowing Policy"
                            policy_img_1={whistle_1}
                            policy_imgalttext_1="Whistle Blowing Policy - Elysium Plantation"
                            policy_img_2={whistle_2}
                            policy_imgalttext_2="Whistle Blowing Policy - Elysium Plantation"
                            policy_img_3={whistle_3}
                            policy_imgalttext_3="Whistle Blowing Policy - Elysium Plantation"
                        />
                        <NoPolicyYet
                            policy_title="Anti Bribery and Anti Corruption Policy"
                        />
                        {/* <NoPolicyYet
                            policy_title="Terms of Reference"
                        />
                        <NoPolicyYet
                            policy_title="Corporate Governance"
                        /> */}

                    </section>
                </div>
                
            </div>
        </>
        
        
    );
}

export default CompanyPoliciesPage;