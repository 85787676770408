import React, { useState, useEffect } from 'react'; 
import '../../App.css';
import './PhotoGallery.scss';
  
import { Image } from 'antd';

function IBGFertilizerGallery() {

    useEffect(() => {
        document.title = "IBG Fertilizer Gallery - Elysium Plantation Berhad ";  
    }, []);

    // IBG Fertilizer
    const ibg_1 = require("../../images/locations/IBG-Fertilizer/ibg-01.jpeg");
    const ibg_2 = require("../../images/locations/IBG-Fertilizer/ibg-02.jpeg");
    const ibg_3 = require("../../images/locations/IBG-Fertilizer/ibg-03.jpeg");
    const ibg_4 = require("../../images/locations/IBG-Fertilizer/ibg-04.jpeg");
    const ibg_5 = require("../../images/locations/IBG-Fertilizer/ibg-05.jpeg");
    const ibg_6 = require("../../images/locations/IBG-Fertilizer/ibg-06.jpeg");
    const ibg_7 = require("../../images/locations/IBG-Fertilizer/ibg-08.jpeg");
    const ibg_8 = require("../../images/locations/IBG-Fertilizer/ibg-09.jpeg");

    return (

        <>
            <div className="wrap-gallery">

                <section className='wrap-gallery-hero'>
                    <div className='gallery-hero'> 
                        <div className='gallery-hero-heading'>
                            <h1>IBG Fertilizer</h1>
                            <h2>Photo Gallery</h2>
                        </div>
                    </div>
                </section>

                <div className="ld-divider-right mobile-divd"></div>

                <div className="gallery-container">
                    <div className="gallery-content-1"> 
                        <Image.PreviewGroup>
                            <Image width={300} height={200} src={ibg_1} alt="IBG Fertilizer - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={ibg_2} alt="IBG Fertilizer - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={ibg_3} alt="IBG Fertilizer - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={ibg_4} alt="IBG Fertilizer - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={ibg_5} alt="IBG Fertilizer - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={ibg_6} alt="IBG Fertilizer - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={ibg_7} alt="IBG Fertilizer - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={ibg_8} alt="IBG Fertilizer - Elysium Plantation Berhad" />
                        </Image.PreviewGroup>
                    </div> 
                </div>
                
            </div>
        </>
        
        
    );
}

export default IBGFertilizerGallery;