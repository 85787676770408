import React, { useEffect } from 'react';
import '../../App.css';
import Landing from '../Landing';


function Home() {
 
    useEffect(() => {
        document.title = "Elysium Plantation Berhad";  
    }, []);

    return (
        <>
            <Landing />
        </>
    );
}

export default Home;