import React, { useRef } from 'react'; 
import '../App.css';
import './ContactUs.scss';
import emailjs from '@emailjs/browser';


function ContactUsPage() {

    const form = useRef();
  
    const sendEmail = (e) => {
        e.preventDefault();
  
        //   emailjs.sendForm('SERVICE_ID', 'TEMPLATE_ID', form.current, 'USER_ID')
        emailjs.sendForm('service_75rp7eo', 'template_u9dydxn', form.current, 'pHO4ytkdn67SJMLp9')
            .then((result) => {
                console.log(result.text);
                alert("Form sent successfully! We will contact you as soon as possible!");
            }, (error) => {
                console.log(error.text);
            });

    };
    return (

        <>
            <div className="wrap-contactus">

                <section className='wrap-hero contact-us'>
                    <div className='hero-container'> 
                        <div className='hero-heading'>
                            <h1>Contact Us </h1>
                        </div>
                    </div>
                </section>

                <div className="ld-divider-right mobile-divd"></div>

                <div className="contus-container">
                    <div className="contus-content-1">
                        <div className="contus-header-1">
                            <h2>Get in Touch</h2>
                        </div>

                        <form className="contus-form-content" ref={form} onSubmit={sendEmail} >
                            <div className="contus-form-row-1">
                                <div>
                                    <input type="text" id="fname" name="fname" placeholder="First Name*" required/><br/>
                                </div>

                                <div>
                                    <input type="text" id="lname" name="lname" placeholder="Last Name*" required/><br/>
                                </div>
                            </div>

                            <div className="contus-form-row-1 email-number">
                                <div>
                                    <input type="email" id="email" name="email" placeholder="Email Address*" required/>
                                </div>

                                <div>
                                    <input type="number" id="phonenumber" name="phonenumber" placeholder="Phone Number" minlength="9" maxlength="11" />
                                </div>
                            </div>

                            <div className="contus-form-row-2">
                                <textarea type="text" id="message" name="message" placeholder="Please leave your message here" required/>
                            </div>
                            
                            <div className="cont-us-form-row-3">
                                <button className="cont-us-form-submit" type="submit">Send Message</button>
                            </div>
                        </form> 
                    </div>
                </div>
                
            </div>
        </>
        
        
    );
}

export default ContactUsPage;