import React, { useEffect } from 'react';
import '../../App.css';
import NewsEventsPage from '../NewsEvents_Page';


function NewsEvents() {
 
    useEffect(() => {
        document.title = "News & Events - Elysium Plantation Berhad ";  
    }, []);

    return (
        <>
            <NewsEventsPage />
        </>
    );
}

export default NewsEvents;