import React, { useState, useEffect } from 'react'; 
import '../../App.css';
import './PhotoGallery.scss';
  
import { Image } from 'antd';


function JakartaGallery() {

    useEffect(() => {
        document.title = "Jakarta Gallery - Elysium Plantation Berhad ";  
    }, []);

    // Jakarta
    const jakarta_1 = require("../../images/locations/Jakarta/jakarta-01.jpg");


    return (

        <>
            <div className="wrap-gallery">

                <section className='wrap-gallery-hero'>
                    <div className='gallery-hero'> 
                        <div className='gallery-hero-heading'>
                            <h1>Jakarta</h1>
                            <h2>Photo Gallery</h2>
                        </div>
                    </div>
                </section>

                <div className="ld-divider-right mobile-divd"></div>

                <div className="gallery-container">
                    <div className="gallery-content-1"> 
                        <Image.PreviewGroup>
                            <Image width={300} height={200} src={jakarta_1} alt="Jakarta - Elysium Plantation Berhad" />
                        </Image.PreviewGroup>
                    </div> 
                </div>
                
            </div>
        </>
        
        
    );
}

export default JakartaGallery;