import React, { useState, useEffect } from 'react'; 
import '../../App.css';
import './PhotoGallery.scss';
  
import { Image } from 'antd';

function SungaiSiputGallery() {

    useEffect(() => {
        document.title = "Sungai Siput Gallery - Elysium Plantation Berhad ";  
    }, []);

    // Sungai Siput
    const sungaisiput_1 = require("../../images/locations/Sungai-Siput/sungaisiput-01.jpeg");
    const sungaisiput_2 = require("../../images/locations/Sungai-Siput/sungaisiput-02.jpeg");
    const sungaisiput_3 = require("../../images/locations/Sungai-Siput/sungaisiput-03.jpeg");
    const sungaisiput_4 = require("../../images/locations/Sungai-Siput/sungaisiput-04.jpeg");
    const sungaisiput_5 = require("../../images/locations/Sungai-Siput/sungaisiput-05.jpeg");
    const sungaisiput_6 = require("../../images/locations/Sungai-Siput/sungaisiput-06.jpeg");
    const sungaisiput_7 = require("../../images/locations/Sungai-Siput/sungaisiput-07.jpeg");
    const sungaisiput_8 = require("../../images/locations/Sungai-Siput/sungaisiput-08.jpeg");
    const sungaisiput_9 = require("../../images/locations/Sungai-Siput/sungaisiput-09.jpeg");
    const sungaisiput_10 = require("../../images/locations/Sungai-Siput/sungaisiput-10.jpeg");

    return (

        <>
            <div className="wrap-gallery">

                <section className='wrap-gallery-hero'>
                    <div className='gallery-hero'> 
                        <div className='gallery-hero-heading'>
                            <h1>Sungai Siput</h1>
                            <h2>Photo Gallery</h2>
                        </div>
                    </div>
                </section>

                <div className="ld-divider-right mobile-divd"></div>

                <div className="gallery-container">
                    <div className="gallery-content-1"> 
                        <Image.PreviewGroup>
                            <Image width={300} height={200} src={sungaisiput_1} alt="Sungai Siput - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={sungaisiput_2} alt="Sungai Siput - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={sungaisiput_3} alt="Sungai Siput - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={sungaisiput_4} alt="Sungai Siput - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={sungaisiput_5} alt="Sungai Siput - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={sungaisiput_6} alt="Sungai Siput - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={sungaisiput_7} alt="Sungai Siput - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={sungaisiput_8} alt="Sungai Siput - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={sungaisiput_9} alt="Sungai Siput - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={sungaisiput_10} alt="Sungai Siput - Elysium Plantation Berhad" />
                        </Image.PreviewGroup>
                    </div> 
                </div>
                
            </div>
        </>
        
        
    );
}

export default SungaiSiputGallery;