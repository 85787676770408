import React, { useState, useEffect } from 'react'; 
import '../../App.css';
import './PhotoGallery.scss';
  
import { Image } from 'antd';


function KotaTinggiGallery() {

    useEffect(() => {
        document.title = "Kota Tinggi Gallery - Elysium Plantation Berhad ";  
    }, []);

    // Kota Tinggi
    const kotatinggi_1 = require("../../images/locations/Kota-Tinggi/KotaTinggi-01.jpg");
    const kotatinggi_2 = require("../../images/locations/Kota-Tinggi/KotaTinggi-02.jpg");
    const kotatinggi_3 = require("../../images/locations/Kota-Tinggi/KotaTinggi-03.jpg");
    const kotatinggi_4 = require("../../images/locations/Kota-Tinggi/KotaTinggi-04.jpg");
    const kotatinggi_5 = require("../../images/locations/Kota-Tinggi/KotaTinggi-05.jpg");
    const kotatinggi_6 = require("../../images/locations/Kota-Tinggi/KotaTinggi-06.jpg");
    const kotatinggi_7 = require("../../images/locations/Kota-Tinggi/KotaTinggi-07.jpg");
    const kotatinggi_8 = require("../../images/locations/Kota-Tinggi/KotaTinggi-08.jpg");
    const kotatinggi_9 = require("../../images/locations/Kota-Tinggi/KotaTinggi-09.jpg");
    const kotatinggi_10 = require("../../images/locations/Kota-Tinggi/KotaTinggi-16.jpg");

    const kotatinggi_11 = require("../../images/locations/Kota-Tinggi/KotaTinggi-17.jpg");
    const kotatinggi_12 = require("../../images/locations/Kota-Tinggi/KotaTinggi-18.jpg");
    // const kotatinggi_13 = require("../../images/locations/Kota-Tinggi/KotaTinggi-19.jpg");
    const kotatinggi_14 = require("../../images/locations/Kota-Tinggi/KotaTinggi-21.jpg");
    const kotatinggi_15 = require("../../images/locations/Kota-Tinggi/KotaTinggi-22.jpg");
    const kotatinggi_16 = require("../../images/locations/Kota-Tinggi/KotaTinggi-23.jpg");
    const kotatinggi_17 = require("../../images/locations/Kota-Tinggi/KotaTinggi-24.jpg");
    // const kotatinggi_18 = require("../../images/locations/Kota-Tinggi/KotaTinggi-25.jpg");
    // const kotatinggi_19 = require("../../images/locations/Kota-Tinggi/KotaTinggi-26.jpg");
    const kotatinggi_20 = require("../../images/locations/Kota-Tinggi/KotaTinggi-27.jpg");

    // const kotatinggi_21 = require("../../images/locations/Kota-Tinggi/KotaTinggi-28.jpg");
    const kotatinggi_22 = require("../../images/locations/Kota-Tinggi/KotaTinggi-29.jpg");
    const kotatinggi_23 = require("../../images/locations/Kota-Tinggi/KotaTinggi-30.jpg");
    const kotatinggi_24 = require("../../images/locations/Kota-Tinggi/KotaTinggi-31.jpg");
    const kotatinggi_25 = require("../../images/locations/Kota-Tinggi/KotaTinggi-32.jpg");
    const kotatinggi_26 = require("../../images/locations/Kota-Tinggi/KotaTinggi-33.jpg");
    // const kotatinggi_27 = require("../../images/locations/Kota-Tinggi/KotaTinggi-34.jpg");
    const kotatinggi_28 = require("../../images/locations/Kota-Tinggi/KotaTinggi-35.jpg");
    // const kotatinggi_29 = require("../../images/locations/Kota-Tinggi/KotaTinggi-36.jpg");

    return (

        <>
            <div className="wrap-gallery">

                <section className='wrap-gallery-hero'>
                    <div className='gallery-hero'> 
                        <div className='gallery-hero-heading'>
                            <h1>Kota Tinggi</h1>
                            <h2>Photo Gallery</h2>
                        </div>
                    </div>
                </section>

                <div className="ld-divider-right mobile-divd"></div>

                <div className="gallery-container">
                    <div className="gallery-content-1"> 
                        <Image.PreviewGroup>
                            <Image width={300} height={200} src={kotatinggi_1} alt="Kota Tinggi - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={kotatinggi_2} alt="Kota Tinggi - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={kotatinggi_3} alt="Kota Tinggi - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={kotatinggi_4} alt="Kota Tinggi - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={kotatinggi_5} alt="Kota Tinggi - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={kotatinggi_6} alt="Kota Tinggi - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={kotatinggi_7} alt="Kota Tinggi - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={kotatinggi_8} alt="Kota Tinggi - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={kotatinggi_9} alt="Kota Tinggi - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={kotatinggi_10} alt="Kota Tinggi - Elysium Plantation Berhad" />
                            
                            <Image width={300} height={200} src={kotatinggi_11} alt="Kota Tinggi - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={kotatinggi_12} alt="Kota Tinggi - Elysium Plantation Berhad" />
                            {/* <Image width={300} height={200} src={kotatinggi_13} alt="Kota Tinggi - Elysium Plantation Berhad" /> */}
                            <Image width={300} height={200} src={kotatinggi_14} alt="Kota Tinggi - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={kotatinggi_15} alt="Kota Tinggi - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={kotatinggi_16} alt="Kota Tinggi - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={kotatinggi_17} alt="Kota Tinggi - Elysium Plantation Berhad" />
                            {/* <Image width={300} height={200} src={kotatinggi_18} alt="Kota Tinggi - Elysium Plantation Berhad" /> repeat */}
                            {/* <Image width={300} height={200} src={kotatinggi_19} alt="Kota Tinggi - Elysium Plantation Berhad" /> repeat */}
                            <Image width={300} height={200} src={kotatinggi_20} alt="Kota Tinggi - Elysium Plantation Berhad" />
                            
                            {/* <Image width={300} height={200} src={kotatinggi_21} alt="Kota Tinggi - Elysium Plantation Berhad" /> repeat */}
                            <Image width={300} height={200} src={kotatinggi_22} alt="Kota Tinggi - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={kotatinggi_23} alt="Kota Tinggi - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={kotatinggi_24} alt="Kota Tinggi - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={kotatinggi_25} alt="Kota Tinggi - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={kotatinggi_26} alt="Kota Tinggi - Elysium Plantation Berhad" />
                            {/* <Image width={300} height={200} src={kotatinggi_27} alt="Kota Tinggi - Elysium Plantation Berhad" /> */}
                            <Image width={300} height={200} src={kotatinggi_28} alt="Kota Tinggi - Elysium Plantation Berhad" />
                            {/* <Image width={300} height={200} src={kotatinggi_29} alt="Kota Tinggi - Elysium Plantation Berhad" /> */}
                        </Image.PreviewGroup>

                    </div> 
                </div>
                
            </div>
        </>
        
        
    );
}

export default KotaTinggiGallery;