import React, { useState, useEffect } from 'react'; 
import '../../App.css';
import './PhotoGallery.scss';
  
import { Image } from 'antd';

function DesaruGallery() {

    useEffect(() => {
        document.title = "Desaru Gallery - Elysium Plantation Berhad ";  
    }, []);

    // Desaru
    const desaru_1 = require("../../images/locations/Desaru/desaru-01.jpeg");
    const desaru_2 = require("../../images/locations/Desaru/desaru-02.jpeg");
    const desaru_3 = require("../../images/locations/Desaru/desaru-03.jpeg");
    const desaru_4 = require("../../images/locations/Desaru/desaru-04.jpeg");
    const desaru_5 = require("../../images/locations/Desaru/desaru-05.jpeg");
    const desaru_6 = require("../../images/locations/Desaru/desaru-06.jpeg");
    const desaru_7 = require("../../images/locations/Desaru/desaru-07.jpeg");
    const desaru_8 = require("../../images/locations/Desaru/desaru-08.jpeg");
    const desaru_9 = require("../../images/locations/Desaru/desaru-09.jpeg");
    const desaru_10 = require("../../images/locations/Desaru/desaru-10.jpeg");
    const desaru_11 = require("../../images/locations/Desaru/desaru-11.jpeg");

    return (

        <>
            <div className="wrap-gallery">

                <section className='wrap-gallery-hero'>
                    <div className='gallery-hero'> 
                        <div className='gallery-hero-heading'>
                            <h1>Desaru</h1>
                            <h2>Photo Gallery</h2>
                        </div>
                    </div>
                </section>

                <div className="ld-divider-right mobile-divd"></div>

                <div className="gallery-container">
                    <div className="gallery-content-1"> 
                        <Image.PreviewGroup>
                            <Image width={300} height={200} src={desaru_1} alt="Desaru - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={desaru_2} alt="Desaru - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={desaru_3} alt="Desaru - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={desaru_4} alt="Desaru - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={desaru_5} alt="Desaru - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={desaru_6} alt="Desaru - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={desaru_7} alt="Desaru - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={desaru_8} alt="Desaru - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={desaru_9} alt="Desaru - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={desaru_10} alt="Desaru - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={desaru_11} alt="Desaru - Elysium Plantation Berhad" />
                        </Image.PreviewGroup>
                    </div> 
                </div>
                
            </div>
        </>
        
        
    );
}

export default DesaruGallery;