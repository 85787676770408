import React from 'react'; 
import '../App.css';
import './NewsEvents.scss';


function NewsArticleItem(props) { 

    return (
        <>
            <div className="news-cont-item">
                <a href={props.news_link} target="_blank" rel="noreferrer nofollow">
                    <figure>
                        <img src={props.news_imgsrc} alt={props.news_imgalttext} />
                    </figure>
                </a>

                <div>
                    <a href={props.news_link} target="_blank" rel="noreferrer nofollow">
                        <h2>{props.news_title} </h2>
                    </a>
                </div>
            </div>
        </>
    );
}

function NewsEventsPage() {

    const news_1 = require("../images/BG_29.png");
    const news_2 = require("../images/BG_30.png");
    const news_3 = require("../images/BG_31.png");
    // const news_4 = require("../images/BG_32.png");
    const news_5 = require("../images/BG_33.png");
    const news_6 = require("../images/BG_34.png");

    return (

        <>
            <div className="wrap-newsevents">

                <section className='wrap-hero news-events'>
                    <div className='hero-container'> 
                        <div className='hero-heading'>
                            <h1>News & Events </h1>
                        </div>
                    </div>
                </section>

                <div className="ld-divider-right mobile-divd"></div>

                <div className="news-container">
                    <div className="news-content-1">
                        <NewsArticleItem
                            news_link="https://carboncredits.com/net-zero-framework-for-malaysia-to-be-released-this-year/"
                            news_imgsrc={news_1}
                            news_imgalttext="Net-Zero Framework for Malaysia to be Released this Year"
                            news_title="Net-Zero Framework for Malaysia to be Released this Year"
                        />
                        <NewsArticleItem
                            news_link="https://carboncredits.com/biofuels-from-biomass-lowers-prices/"
                            news_imgsrc={news_2}
                            news_imgalttext="Breakthrough in Low Cost Biofuels from Biomass"
                            news_title="Breakthrough in Low Cost Biofuels from Biomass"
                        />
                        <NewsArticleItem
                            news_link="https://carboncredits.com/bursa-malaysia-vcm-exchange/"
                            news_imgsrc={news_3}
                            news_imgalttext="Bursa Malaysia to launch VCM exchange"
                            news_title="Bursa Malaysia to launch VCM exchange"
                        />
                        {/* <NewsArticleItem
                            news_link=""
                            news_imgsrc={news_4}
                            news_imgalttext="Embracing sustainability"
                            news_title="Embracing sustainability"
                        /> */}
                        <NewsArticleItem
                            news_link="https://www.klsescreener.com/v2/news/view/1008427/ex-epf-ceo-businesses-should-consider-setting-up-sustainable-unit"
                            news_imgsrc={news_5}
                            news_imgalttext="Ex-EPF CEO: Businesses should consider setting up sustainable unit"
                            news_title="Ex-EPF CEO: Businesses should consider setting up sustainable unit"
                        />
                        <NewsArticleItem
                            news_link="https://www.shareandstocks.com/plantation-ministry-goes-the-extra-mile-in-environment-and-habitat-conservation/"
                            news_imgsrc={news_6}
                            news_imgalttext="Plantation Ministry Goes The Extra Mile In Environment And Habitat Conservation"
                            news_title="Plantation Ministry Goes The Extra Mile In Environment And Habitat Conservation"
                        />
                    </div>
                </div>
                
            </div>
        </>
        
        
    );
}

export default NewsEventsPage;