import React, { useState, useEffect } from 'react'; 
import '../../App.css';
import './PhotoGallery.scss';
  
import { Image } from 'antd';


function ChemomoiGallery() {

    useEffect(() => {
        document.title = "Chemomoi Gallery - Elysium Plantation Berhad ";  
    }, []);

    // Chemomoi
    const chemomoi_1 = require("../../images/locations/Chemomoi/Chemomoi-01.jpg");
    const chemomoi_2 = require("../../images/locations/Chemomoi/Chemomoi-02.jpg");
    const chemomoi_3 = require("../../images/locations/Chemomoi/Chemomoi-03.jpg");
    const chemomoi_4 = require("../../images/locations/Chemomoi/Chemomoi-04.jpg");

    return (

        <>
            <div className="wrap-gallery">

                <section className='wrap-gallery-hero'>
                    <div className='gallery-hero'> 
                        <div className='gallery-hero-heading'>
                            <h1>Chemomoi</h1>
                            <h2>Photo Gallery</h2>
                        </div>
                    </div>
                </section>

                <div className="ld-divider-right mobile-divd"></div>

                <div className="gallery-container">
                    <div className="gallery-content-1"> 
                        <Image.PreviewGroup>
                            <Image width={300} height={200} src={chemomoi_1} alt="Chemomoi - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={chemomoi_2} alt="Chemomoi - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={chemomoi_3} alt="Chemomoi - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={chemomoi_4} alt="Chemomoi - Elysium Plantation Berhad" />
                        </Image.PreviewGroup>
                    </div> 
                </div>
                
            </div>
        </>
        
        
    );
}

export default ChemomoiGallery;