import React, { useEffect } from 'react';
import '../../App.css';
import CompanyPoliciesPage from '../CompanyPolicies_Page';


function CompanyPolicies() {
 
    useEffect(() => {
        document.title = "Company Policies - Elysium Plantation Berhad ";  
    }, []);

    return (
        <>
            <CompanyPoliciesPage />
        </>
    );
}

export default CompanyPolicies;