import React, { useEffect } from 'react';
import '../../App.css';
import OurBusinessPage from '../OurBusiness_Page';


function OurBusiness() {
 
    useEffect(() => {
        document.title = "Our Business - Elysium Plantation Berhad ";  
    }, []);

    return (
        <>
            <OurBusinessPage />
        </>
    );
}

export default OurBusiness;