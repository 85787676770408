import React, { useState, useEffect } from 'react'; 
import '../../App.css';
import './PhotoGallery.scss';
  
import { Image } from 'antd';


function TawauGallery() {

    useEffect(() => {
        document.title = "Tawau Gallery - Elysium Plantation Berhad ";  
    }, []);

    // Tawau
    const tawau_1 = require("../../images/locations/Tawau/Tawau-12.jpg");
    // const tawau_2 = require("../../images/locations/Tawau/Tawau-04.jpg");
    const tawau_3 = require("../../images/locations/Tawau/Tawau-09.jpg");
    const tawau_4 = require("../../images/locations/Tawau/Tawau-08.jpg");
    const tawau_5 = require("../../images/locations/Tawau/Tawau-13.jpg");
    const tawau_6 = require("../../images/locations/Tawau/Tawau-01.jpg"); 
    const tawau_7 = require("../../images/locations/Tawau/Tawau-14.jpg");

    const tawau_8 = require("../../images/locations/Tawau/Tawau-18.jpg");
    const tawau_9 = require("../../images/locations/Tawau/Tawau-15.jpg");
    const tawau_10 = require("../../images/locations/Tawau/Tawau-21.jpg");
    const tawau_11 = require("../../images/locations/Tawau/Tawau-22.jpg");
    const tawau_12 = require("../../images/locations/Tawau/Tawau-23.jpg");
    // const tawau_13 = require("../../images/locations/Tawau/Tawau-16.jpg");
    const tawau_14 = require("../../images/locations/Tawau/Tawau-24.jpg");

    const tawau_15 = require("../../images/locations/Tawau/Tawau-16.jpg");
    // const tawau_16 = require("../../images/locations/Tawau/Tawau-25.jpg");
    const tawau_17 = require("../../images/locations/Tawau/Tawau-26.jpg");
    const tawau_18 = require("../../images/locations/Tawau/Tawau-28.jpg");
    const tawau_19 = require("../../images/locations/Tawau/Tawau-27.jpg");
    const tawau_20 = require("../../images/locations/Tawau/Tawau-29.jpg");
    const tawau_21 = require("../../images/locations/Tawau/Tawau-22.jpg");

    const tawau_22 = require("../../images/locations/Tawau/Tawau-44.jpg");
    const tawau_23 = require("../../images/locations/Tawau/Tawau-43.jpg");
    const tawau_24 = require("../../images/locations/Tawau/Tawau-47.jpg");
    const tawau_25 = require("../../images/locations/Tawau/Tawau-25.jpg");
    const tawau_26 = require("../../images/locations/Tawau/Tawau-46.jpg");
    const tawau_27 = require("../../images/locations/Tawau/Tawau-51.jpg");
    const tawau_28 = require("../../images/locations/Tawau/Tawau-50.jpg");

    return (

        <>
            <div className="wrap-gallery">

                <section className='wrap-gallery-hero'>
                    <div className='gallery-hero'> 
                        <div className='gallery-hero-heading'>
                            <h1>Tawau</h1>
                            <h2>Photo Gallery</h2>
                        </div>
                    </div>
                </section>

                <div className="ld-divider-right mobile-divd"></div>

                <div className="gallery-container">
                    <div className="gallery-content-1"> 
                        <Image.PreviewGroup>
                            <Image width={300} height={200} src={tawau_1} alt="Tawau - Elysium Plantation Berhad" />
                            {/* <Image width={300} height={200} src={tawau_2} alt="Tawau - Elysium Plantation Berhad" /> */}
                            <Image width={300} height={200} src={tawau_3} alt="Tawau - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={tawau_4} alt="Tawau - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={tawau_5} alt="Tawau - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={tawau_6} alt="Tawau - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={tawau_7} alt="Tawau - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={tawau_8} alt="Tawau - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={tawau_9} alt="Tawau - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={tawau_10} alt="Tawau - Elysium Plantation Berhad" />
                            
                            <Image width={300} height={200} src={tawau_11} alt="Tawau - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={tawau_12} alt="Tawau - Elysium Plantation Berhad" />
                            {/* <Image width={300} height={200} src={tawau_13} alt="Tawau - Elysium Plantation Berhad" /> */}
                            <Image width={300} height={200} src={tawau_14} alt="Tawau - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={tawau_15} alt="Tawau - Elysium Plantation Berhad" />
                            {/* <Image width={300} height={200} src={tawau_16} alt="Tawau - Elysium Plantation Berhad" /> */}
                            <Image width={300} height={200} src={tawau_17} alt="Tawau - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={tawau_18} alt="Tawau - Elysium Plantation Berhad" /> 
                            <Image width={300} height={200} src={tawau_19} alt="Tawau - Elysium Plantation Berhad" /> 
                            <Image width={300} height={200} src={tawau_20} alt="Tawau - Elysium Plantation Berhad" />
                            
                            <Image width={300} height={200} src={tawau_21} alt="Tawau - Elysium Plantation Berhad" /> 
                            <Image width={300} height={200} src={tawau_22} alt="Tawau - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={tawau_23} alt="Tawau - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={tawau_24} alt="Tawau - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={tawau_25} alt="Tawau - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={tawau_26} alt="Tawau - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={tawau_27} alt="Tawau - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={tawau_28} alt="Tawau - Elysium Plantation Berhad" />
                            {/* <Image width={300} height={200} src={tawau_29} alt="Tawau - Elysium Plantation Berhad" /> */}
                        </Image.PreviewGroup>
                    </div>
                </div>
                
            </div>
        </>
        
        
    );
}

export default TawauGallery;