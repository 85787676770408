import React from 'react';
import './Footer.scss';
import { AiFillYoutube } from "react-icons/ai";

function Footer() {

    // const footer_img = require("/logo-06.png");
    return (

        <>
            <div className="wrap-footer" >
                <div className="footer-container">
                    <div className="footer-cont-1">
                        <figure>
                            <img src="/logo-06.png" alt="Elysium Plantation Sitemaps" />
                        </figure>

                        <address>
                            V05-03-06 Signature 1, <br/>
                            Lingkaran SV Sunway Velocity <br/>
                            55100 Kuala Lumpur.
                        </address>

                        <p>Operating Hours: <span>10.00am - 7:00pm</span></p>
                        <p>Phone: <a href="tel:0392868080">03-9286 8080</a></p>
                        <p>Email: <a href="mailto:info@elysiumberhad.com.my" target="_blank" rel='noopenner noreferrer'>info@elysiumberhad.com.my</a></p>
                        <p>Facebook: <a href="https://www.facebook.com/profile.php?id=100090841521435&mibextid=LQQJ4d" target="_blank" rel='noopenner noreferrer'>Elysium Plantation Berhad</a></p>
                        <p>YouTube: <a href="https://youtube.com/@ElysiumPlantation" target="_blank" rel='noopenner noreferrer'>Elysium Plantation Berhad</a></p>
                    </div>

                    <div className="footer-cont-2"> 
                        <div className="footer-sitemap">
                            <h5>Home</h5>
                            <span aria-hidden="true" data-icon="&#xe903;"><a href="/home/#vision-and-mission" rel="noreferrer nofollow">Vision & Mission</a></span>
                            <span aria-hidden="true" data-icon="&#xe903;"><a href="/home/#about-us" rel="noreferrer nofollow">About Us</a></span>
                            <span aria-hidden="true" data-icon="&#xe903;"><a href="/home/#useful-links" rel="noreferrer nofollow">Useful Links</a></span>
                        </div>
                        <div className="footer-sitemap">
                            <h5>Corporate Info</h5>
                            <span aria-hidden="true" data-icon="&#xe903;"><a href="/corporate-info/#board-of-directors" rel="noreferrer nofollow">Board of directors</a></span>
                            <span aria-hidden="true" data-icon="&#xe903;"><a href="/corporate-info/#corporate-directory" rel="noreferrer nofollow">Corporate Directory</a></span>
                            <span aria-hidden="true" data-icon="&#xe903;"><a href="/corporate-info/#corporate-offices" rel="noreferrer nofollow">Corporate Offices</a></span>
                            <span aria-hidden="true" data-icon="&#xe903;"><a href="/corporate-info/#corporate-trustee" rel="noreferrer nofollow">Corporate Trustee</a></span>
                            <span aria-hidden="true" data-icon="&#xe903;"><a href="/corporate-info/#corporate-structure" rel="noreferrer nofollow">Corporate Structure</a></span>
                        </div>
                        <div className="footer-sitemap">
                            <h5>Our Business</h5>
                            <span aria-hidden="true" data-icon="&#xe903;"><a href="/our-business/#core-business" rel="noreferrer nofollow">Core Business</a></span>
                            <span aria-hidden="true" data-icon="&#xe903;"><a href="/our-business/#locations" rel="noreferrer nofollow">Locations</a></span>
                            <span aria-hidden="true" data-icon="&#xe903;"><a href="/our-business/#photo-gallery" rel="noreferrer nofollow">Photo Gallery</a></span>
                        </div>
                        <div className="footer-sitemap">
                            <h5>Company Policies</h5>
                            <span aria-hidden="true" data-icon="&#xe903;"><a href="/company-policies/" rel="noreferrer nofollow">Elysium Plantation Policies</a></span>
                        </div>
                        <div className="footer-sitemap">
                            <h5>News & Events</h5>
                            <span aria-hidden="true" data-icon="&#xe903;"><a href="/news-and-events/" rel="noreferrer nofollow">All News & Events</a></span>
                        </div>
                        <div className="footer-sitemap">
                            <h5>Contact Us</h5>
                            <span aria-hidden="true" data-icon="&#xe903;"><a href="/contact-us/" rel="noreferrer nofollow">Get in Touch</a></span>
                        </div>
                    </div>
                </div>

                <div className="footer-copyright">
                    <span>Copyright © {new Date().getFullYear()} Elysium Plantation Berhad . All Rights Reserved</span>
                </div>
            </div>
        </>
    );
}

export default Footer;

