// import React, { useRef, useState } from 'react';
import React from 'react';
import '../App.css';
import './Landing.scss';
import CountUp from 'react-countup';

function UsefulLinksItem(props) {
    return (
        <>
            <div className="ld-uflinks-item-1"> 
                <a href={props.useful_link} target="_blank" rel="noreferrer nofollow">
                    <img src={props.useful_imgsrc} alt={props.useful_imgalttext} />
                </a>

                <div>
                    <p>{props.useful_title}</p>
                </div>
            </div>
        </>
    )
}

function Landing() {
    // const showcase_img = require("../images/BG_39.png");
    // const showcase_vid = require("../images/locations/MainShowcase.mp4");
    // const showcase_vid = require("../images/locations/Showcase-04.mp4");
    // const aboutus_01 = require("../images/BG_40.png");
    const aboutus_01 = require("../images/trees-planted-bg-04.jpg");
    const usefullinks_01 = require("../images/BG_02_01.png");
    const usefullinks_02 = require("../images/BG_03_01.png");
    const usefullinks_03 = require("../images/BG_14_01.png");
    const usefullinks_04 = require("../images/BG_04_01.png");
    const usefullinks_05 = require("../images/BG_05_01.png");
    const usefullinks_06 = require("../images/BG_07_01.png");
    const usefullinks_07 = require("../images/BG_15_01.png");
    const usefullinks_08 = require("../images/BG_08_01.png");

    return (

        <>
        <div className="wrap-landing">

            <div className='wrap-hero'>
                <div className='hero-vid-container'>
                    {/* <video id="myVideo"  loop="true" autoplay="autoplay" muted > */}
                    <video id="myVideo"  loop="loop" autoplay="autoplay" muted> 
                        <source src="/asset/video/Showcase.mp4"  type="video/mp4" /> 
                    </video>
                </div>
                {/* <video autoplay muted loop id="myVideo">
                    <source src={showcase_vid} type="video/mp4" />
                </video> */}
                <div className='hero-container'> 
                    <div className='hero-heading'>
                        <h1 className="animate__animated animate__fadeInDown">Elysium Plantation Berhad</h1>
                    </div>
                </div>
            </div>
            
            <div className='wrap-land-content'>
                <div className='land-content-container'> 
                    <div className='ld-content-1' id="vision-and-mission">
                        <div className='ld-cont-heading'>
                            <h1 className="animate__animated animate__fadeInDown">Elysium Plantation Berhad</h1>
                        </div>

                        <div className="ld-cont-item">
                            <div className="ld-cont-item-1">
                                <h2>Vision</h2>
                                <p>SAVE TREES, SAVE LIVES</p>
                            </div>
                            
                            <div className="ld-cont-item-2">
                                <h2>Trees Planted</h2>
                                {/* <span>50,000+</span> */}
                                <span>
                                    <CountUp 
                                        start = {0}
                                        end = {50000} 
                                        duration = {5}
                                        // delay = {2}
                                        separator = ","
                                        enableScrollSpy = {true}
                                        scrollSpyDelay = {1000}
                                    /> +
                                </span>
                            </div>
                            
                            <div className="ld-cont-item-1">
                                <h2>Mission</h2>
                                <p>Implement sustainable timber plantation supply & standards along with environmental and social development benefit</p>
                            </div>
                        </div>
                    </div>
 
                    <div className="ld-divider-right type-1"></div>

                    <div className='ld-content-2' id="about-us">
                        <div className="ld-aboutus-header">
                            <h2>About Us</h2>
                        </div>
                        
                        <div className="ld-aboutus-content">
                            <div className="ld-aboutus-item-1">
                                <h3>Elysium Plantation Berhad at a Glance</h3>
                                <p>Elysium Plantation Berhad was incorporated in Malaysia on 29th August 2022 as a limited company under the name of ELYSIUM PLANTATION BERHAD with
                                    the registration number 202201032060 (1477757-V) and the business is commenced in the same year. The Group is principally engaged in the cultivation and processing of 
                                    eucalyptus plantations. Other businesses include timber logging, research & development.
                                </p>
                            </div>

                            <div className="ld-aboutus-item-2">
                                <img src={aboutus_01} alt="About Elysium Plantation" />
                            </div>
                        </div>
                    </div>

                    <div className="ld-divider-left"></div> 

                    <div className='ld-content-3' id="useful-links">
                        <div className="ld-uflinks-header">
                            <h2>Useful Links</h2>
                        </div>
                        
                        <div className="ld-uflinks-content">
                            <UsefulLinksItem
                                useful_link="https://www.mpic.gov.my/mpi/en/"
                                useful_imgsrc={usefullinks_01}
                                useful_imgalttext="Ministry of Plantation Industries and Commodities (MPIC)"
                                useful_title="Ministry of Plantation Industries and Commodities (MPIC)"
                            />  
                            <UsefulLinksItem
                                useful_link="https://www.mtib.gov.my/"
                                useful_imgsrc={usefullinks_02}
                                useful_imgalttext="Malaysia Timber Industry Board (MTIB)"
                                useful_title="Malaysia Timber Industry Board (MTIB)"
                            />  
                            <UsefulLinksItem
                                useful_link="https://www.forestry.gov.my/en/"
                                useful_imgsrc={usefullinks_03}
                                useful_imgalttext="Forestry Department of Peninsular Malaysia"
                                useful_title="Forestry Department of Peninsular Malaysia"
                            />  
                            <UsefulLinksItem
                                useful_link="https://www.mafi.gov.my/"
                                useful_imgsrc={usefullinks_04}
                                useful_imgalttext="Ministry of Agriculture and Food Industries (MAFI)"
                                useful_title="Ministry of Agriculture and Food Industries (MAFI)"
                            />  
                            <UsefulLinksItem
                                useful_link="https://www.pertanian.go.id/"
                                useful_imgsrc={usefullinks_05}
                                useful_imgalttext="Kementerian Pertanian Republik Indonesia"
                                useful_title="Kementerian Pertanian Republik Indonesia"
                            />  
                            <UsefulLinksItem
                                useful_link="https://upm.edu.my/"
                                useful_imgsrc={usefullinks_06}
                                useful_imgalttext="Universiti Putra Malaysia (UPM)"
                                useful_title="Universiti Putra Malaysia (UPM)"
                            />  
                            <UsefulLinksItem
                                useful_link="http://www.doa.gov.my/"
                                useful_imgsrc={usefullinks_07}
                                useful_imgalttext="Portal Rasmi Jabatan Pertanian"
                                useful_title="Portal Rasmi Jabatan Pertanian"
                            />  
                            <UsefulLinksItem
                                useful_link="https://www.pknp.gov.my/"
                                useful_imgsrc={usefullinks_08}
                                useful_imgalttext="Perbadanan Kemajuan Negeri Pahang (PKNP)"
                                useful_title="Perbadanan Kemajuan Negeri Pahang (PKNP)"
                            /> 
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        </>
        
        
    );
}

export default Landing;