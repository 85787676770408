// import React, { useRef, useState } from 'react';
import React, { useState, useMemo } from 'react';
import '../App.css';
import './CorporateInfo.scss';
import { IoMdCopy } from "react-icons/io";
import { notification } from 'antd';

const Context = React.createContext({
    name: 'Default',
});

function CorporateInfoPage() {

    const corp_secretery = require('../images/BG_11.png');
    const office_1 = require('../images/BG_16.png');
    const office_2 = require('../images/BG_17.png');
    const office_3 = require('../images/BG_42.png');
    const office_4 = require('../images/BG_43.png');
    const corp_trustee = require('../images/BG_18_01.png');
    const corp_structure = require('../images/BG_38.png');

    // copy to clipboard
    const [isCopied, setIsCopied] = useState(false);
    const copyToClipboard = () => {
        const linkText = "https://elysiumberhad.com.my/";
        navigator.clipboard.writeText(linkText)
          .then(() => {
            setIsCopied(true);
            openNotification(`bottomRight`);
          })
          .catch((error) => {
            console.error('Error copying link to clipboard:', error);
          });
      };

    const [api, contextHolder] = notification.useNotification();
    const openNotification = (placement) => {
        api.success({
        message: `Copied Successfully`,
        description: "https://elysiumberhad.com.my/",
        placement,
        });
    };

    return (

        <>
        {contextHolder}
        <div className="wrap-corp">

            <section className='wrap-hero'>
                <div className='hero-container'> 
                    <div className='hero-heading'>
                        <h1>Corporate Info</h1>
                    </div>
                </div>
            </section>

            <div className="ld-divider-right mobile-divd"></div>

            <section className="corp-container">
                <section className="corp-content-1" id="board-of-directors"> 
                    <div className="corp-conte-header">
                        <h2>Board of Directors</h2>
                    </div>

                    <div className="corp-conte-1-header">
                        <div>
                            <div>
                                <h3>Dato Alex Lee</h3>
                                <p>Director</p>
                            </div>
                            <div>
                                <h3>Benny Chong</h3>
                                <p>Director</p>
                            </div>
                        </div>
                        <p>The Board of Directors (“Board”) is responsible for ensuring the conduct of affairs of the Elysium Plantation Berhad is carried out in an ethical, fair and transparent manner. The responsibility is to review, adopt and monitor the overall strategic business plan of the company. Hence, oversee and evaluate the conduct and performance of the company’s businesses and operations. </p>
                    </div>

                    <div className="corp-conte-1-item-container-1">
                        <div className="corp-conte-1-item-1">
                            <h4>Jimmy Heng</h4>
                            <p>Chief Executive Officer</p>
                        </div>
                        <div className="corp-conte-1-item-2">
                            <p>Jimmy leads and manages plantation and timber logging operations for Elysium Plantation Berhad. He ensures the smooth running of plantation and timber logging operations, administration, systems, procedures. The major role is to be an integral link between the shareholder’s interest and to enquire all the strategic planning in accordance with the right direction. </p>
                        </div>
                    </div>
                    
                    <div className="corp-conte-1-item-container-2">
                        <div className="corp-conte-1-item-2">
                            <p>Rico is a timber logging expert with over 15 years of experience. We’re thrilled to welcome his expertise to our skilled team of measurers. He enquiries the smooth operation for the timber logging side to maximize the efficiency.  </p>
                        </div>
                        <div className="corp-conte-1-item-1">
                            <h4>Rico Toh</h4>
                            <p>Chief Operations Officer
                            <br/>(Timber Logging) </p>
                        </div>
                    </div>
                    
                    <div className="corp-conte-1-item-container-1">
                        <div className="corp-conte-1-item-1">
                            <h4>Kannadas a/l Raman </h4>
                            <p>Chief Operations Officer
                            <br/>(Plantation)</p>
                        </div>
                        <div className="corp-conte-1-item-2">
                            <p>Kannadas is our plantation specialist with over 40 years’ experience, he has seen the industry change over the years from hinging up to the introduction of high quality species from all around the world and experienced all types of challenges. He is always on hand to help workers on plantation site as the manager pointing to our company plantation and maximize yield for profit.</p>
                        </div>
                    </div>
                    
                    <div className="corp-conte-1-item-container-2">
                        <div className="corp-conte-1-item-2">
                            <p>Angel ensures the smooth running of office administration, especially in the accounting for budget forecasts and company cash flow related measures.</p>
                        </div>
                        <div className="corp-conte-1-item-1">
                            <h4>Angel Lai</h4>
                            <p>Finance Manager </p>
                        </div>
                    </div>
                    
                    <div className="corp-conte-1-item-container-1">
                        <div className="corp-conte-1-item-1">
                            <h4>Katherine Chong </h4>
                            <p>Marketing Manager</p>
                        </div>
                        <div className="corp-conte-1-item-2">
                            <p>She is predominantly responsible for our content creation and marketing. You may also come across Katherine dealing with company enquiries or assisting with events and investors relation.</p>
                        </div>
                    </div>
                </section>
                
                <div className="ld-divider-left"></div>

                <section className="corp-content-2" id="corporate-directory">
                    <div className="corp-conte-header">
                        <h2>Corporate Directory</h2>
                    </div>

                    <div className="corp-conte-2-item">
                        <h3>ELYSIUM PLANTATION BERHAD</h3>
                        <p>A limited liability company </p>
                        <p>Incorporated and domiciled in Malaysia</p>
                        <p>Registration No. 202201032060 (1477757-V) </p>
                        
                    </div>
                </section>
                
                <div className="ld-divider-right"></div>

                <section className="corp-content-3">
                    <div className="corp-conte-header">
                        <h2>Company Secretery</h2>
                    </div>

                    <div className="corp-conte-3-item">
                        <figure>
                            <img src={corp_secretery} alt="Elysium Plantation's Company Secretery" />
                        </figure>
                        
                        <div>
                            <h3>Tan Weng Teong</h3>
                            <p>Malaysian institute of chartered secretaries and administrators (maicsa)</p>
                            <p>License No. MAICSA7016013</p>
                        </div>
                    </div>
                </section>
                
                <div className="ld-divider-left"></div>

                <section className="corp-content-4" id="corporate-offices">
                    <div className="corp-conte-4-item-1">
                        <figure>
                            <img src={office_1} alt="Resigtered Office - Elysium Plantation" />
                        </figure>
                        
                        <div>
                            <h3>Registered Office</h3>
                            <address>
                                NO. 19-2, JALAN METRO PERDANA BARAT 1, <br/>
                                TAMAN USAHAWAN, KEPONG UTARA, <br/>
                                52100 KUALA LUMPUR W.P. KUALA LUMPUR MALAYSIA <br/>
                                Tel: <a href="tel:60362507085" target="_blank" rel="noreferrer nofollow">603-6250 7085 </a> <br/>
                                Fax: 603-6250 7085 <br/>
                                Email: <a href="mailto:info@elysiumberhad.com.my" target="_blank" rel="noreferrer nofollow">info@elysiumberhad.com.my </a>  
                            </address>
                        </div>
                    </div>

                    <div className="corp-conte-4-item-2"> 
                        <div>
                            <h3>Business Office</h3>
                            <address>
                                V05-03-06 SIGNATURE 1, LINGKARAN SV SUNWAY VELOCITY, <br/>
                                55100 KUALA LUMPUR W.P. KUALA LUMPUR MALAYSIA <br/>
                                Tel: <a href="tel:60392868080" target="_blank" rel="noreferrer nofollow">603-9286 8080 </a> <br/>
                                Fax: 603-9286 8080 <br/>
                                Email: <a href="mailto:info@elysiumberhad.com.my" target="_blank" rel="noreferrer nofollow">info@elysiumberhad.com.my </a>  
                            </address>
                        </div>

                        <figure>
                            <img src={office_2} alt="Business Office - Elysium Plantation" />
                        </figure>
                    </div>

                    <div className="corp-conte-4-item-1">
                        <figure>
                            <img src={office_3} alt="Plantation Land Site - Elysium Plantation" />
                        </figure>
                        
                        <div>
                            <h3>Plantation Land Site</h3>
                            <address>
                                NO.113-2 JALAN 3/23A, TAMAN DANAU KOTA OFF, <br/>
                                JALAN GENTING KLANG, <br/>
                                53300 KUALA LUMPUR W.P. KUALA LUMPUR MALAYSIA <br/>
                                Tel: <a href="tel:60392868080" target="_blank" rel="noreferrer nofollow">603-9286 8080 </a> <br/>
                                Fax: 603-9286 8080 <br/>
                                Email: <a href="mailto:info@elysiumberhad.com.my" target="_blank" rel="noreferrer nofollow">info@elysiumberhad.com.my </a>  
                            </address>
                        </div>
                    </div>

                    <div className="corp-conte-4-item-2"> 
                        <div>
                            <h3>Logging Timber Land Site </h3>
                            <address>
                                JALAN GENTING KLANG, <br/>
                                53300 KUALA LUMPUR W.P. KUALA LUMPUR MALAYSIA <br/>
                                Tel: <a href="tel:60392868080" target="_blank" rel="noreferrer nofollow">603-9286 8080 </a> <br/>
                                Fax: 603-9286 8080 <br/>
                                Email: <a href="mailto:info@elysiumberhad.com.my" target="_blank" rel="noreferrer nofollow">info@elysiumberhad.com.my </a>  
                            </address>
                        </div>

                        <figure>
                            <img src={office_4} alt="Logging Timber Land Site - Elysium Plantation" />
                        </figure>
                    </div>
                </section>

                <div className="ld-divider-right"></div>


                <section className="corp-content-5" id="corporate-trustee">
                    <div className="corp-conte-header">
                        <h2>Corporate Trustee</h2>
                    </div>

                    <div className="corp-conte-5-item">
                        <div className="corp-conte-5-item-1">
                            <figure>
                                <img src={corp_trustee} alt="Corporate Trustee - Elysium Plantation" />
                            </figure>
                        </div>

                        <div className="corp-conte-5-item-2">
                            <h3>UBB Amanah Berhad</h3>
                            <p>UBB AMANAH BERHAD is at the forefront of the Malaysian independent trust service industry. It is a fully licensed trust company and has been providing trust services for more than three decades since its incorporation in 1988.</p>
                        </div>
                    </div>
                </section>
                
                <div className="ld-divider-left"></div>

                <section className="corp-content-6">
                    <div className="corp-conte-header">
                        <h2>Company Website</h2>
                    </div>

                    {/* <div className="corp-conte-6-item"> */}
                        {/* <a href="/" rel="noreferrer nofollow">https://elysiumberhad.com.my/</a> */}
                        <button className="corp-conte-6-btn" onClick={copyToClipboard}>
                            <span>https://elysiumberhad.com.my/</span> <IoMdCopy className="corp-conte-6-btn-icon" />
                        </button>
                        
                    {/* </div> */}
                </section>
                
                <div className="ld-divider-right"></div>

                <section className="corp-content-7"  id="corporate-structure">
                    <div className="corp-conte-header">
                        <h2>Corporate Structure</h2>
                    </div>

                    <div className="corp-conte-7-item">
                        <figure>
                            <img src={corp_structure} alt="Corporate Structure - Elysium Plantation" />
                        </figure>
                    </div>
                </section>

            </section>
            
        </div>
        </>
        
        
    );
}

export default CorporateInfoPage;