import React, { Component, useState } from 'react'; 
import '../App.css';
import './CompanyPolicies.scss';
import './OurBusiness.scss';
  

function GalleryItemLink (props) {
    return (
        <>
            <div className="ourb-cont-3-item-1">
                <div className={props.Gallery_classname}>
                    <a href={props.Gallery_link} rel="noreferrer nofollow">                        
                        <h3 className="centered">{props.Gallery_title}</h3>
                        <div className="gallery-line-div"></div>
                        <div className="middle">
                            <a href={props.Gallery_link} rel="noreferrer nofollow">
                                View Gallery
                            </a>
                        </div>
                    </a>
                </div>
            </div>
        </>
    )
}

function OurBusinessPage() {

    const corebusiness_1 = require("../images/BG_20.png");
    const corebusiness_2 = require("../images/BG_21.png");

    const location_karak = require("../images/BG_23.png");
    const location_chemomoi = require("../images/BG_24.png");
    const location_kotatinggi = require("../images/locations/Kota-Tinggi/KotaTinggi-23.jpg");
    const location_tawau = require("../images/locations/Tawau/Tawau-12.jpg");
    const location_jakarta = require("../images/locations/Jakarta/jakarta-01.jpg");
    const location_siput = require("../images/trees-planted-bg-03.jpg");

    return (

        <>
            <div className="wrap-ourb">

                <section className='wrap-hero our-business'>
                    <div className='hero-container'> 
                        <div className='hero-heading'>
                            <h1>Our Business</h1>
                        </div>
                    </div>
                </section>

                <div className="ld-divider-right mobile-divd"></div>

                <div className="ourb-container">
                    <section className="ourb-content-1" id="core-business"  name="core-business">
                        <h2>Core Business</h2>

                        <div className="ourb-cont-1-item">
                            <div className="ourb-cont-1-item-1">
                                <img src={corebusiness_1} alt="Our Core Business - Elysium Plantation" />

                                <h3>Eucalyptus Plantation</h3>
                                <p>The Group owns 500 acres land size for Eucalyptus plantation with Generation 9 species. </p>
                            </div>

                            <div className="ourb-cont-1-item-1">
                                <img src={corebusiness_2} alt="Our Core Business - Elysium Plantation" />

                                <h3>Timber Logging Operation</h3>
                                <p>The Group owns 4,000 acres of land with a total operating capacity of 200 metric tonne per day (mt/hour), situated at Karak, Pahang and Chememoi, Pahang respectively.</p>
                            </div>
                        </div>
                        
                    </section>
                
                    <div className="ld-divider-left"></div>

                    <section className="ourb-content-2" id="locations" name="locations">
                        <h2>Locations</h2>
                        <div className="ourb-cont-2-item">
                            <div className="ourb-cont-2-item-1">
                                <img src={location_karak} alt="Karak - Elysium Plantation" />
                                <h3>Karak, Pahang</h3>
                            </div>

                            <div className="ourb-cont-2-item-1">
                                <img src={location_chemomoi} alt="Chemomoi - Elysium Plantation" />
                                <h3>Chemomoi, Pahang</h3>
                            </div>
                        </div>
                    </section>

                    <div className="ld-divider-right"></div>

                    <section className="ourb-content-3" id="photo-gallery" name="photo-gallery">
                        <h2>Photo Gallery</h2>
                        
                        <div className="ourb-cont-3-item">
                            {/* <div className="ourb-cont-3-item-1">
                                <a href="/our-business/karak-gallery" rel="noreferrer nofollow">
                                    <figure>
                                        <img src={location_karak} alt="Karak - Elysium Plantation" />
                                    </figure>
                                </a>
                                <div className="ourb-view-gallery">
                                    <h3>Karak, Pahang</h3>
                                    <a href="/our-business/karak-gallery" rel="noreferrer nofollow">
                                        <button>View Gallery</button>
                                    </a>
                                </div>
                            </div>*/}

                            <GalleryItemLink 
                                Gallery_classname="gallery_container karak"
                                Gallery_imgsrc={location_karak}
                                Gallery_imgalttext="Karak - Elysium Plantation"
                                Gallery_title="Karak"
                                Gallery_link="/our-business/karak-gallery"
                            />
                            <GalleryItemLink 
                                Gallery_classname="gallery_container chemomoi"
                                Gallery_imgsrc={location_chemomoi}
                                Gallery_imgalttext="Chemomoi - Elysium Plantation"
                                Gallery_title="Chemomoi"
                                Gallery_link="/our-business/chemomoi-gallery"
                            />
                            <GalleryItemLink 
                                Gallery_classname="gallery_container kotatinggi"
                                Gallery_imgsrc={location_kotatinggi}
                                Gallery_imgalttext="Kota Tinggi - Elysium Plantation"
                                Gallery_title="Kota Tinggi"
                                Gallery_link="/our-business/kota-tinggi-gallery"
                            />

                            <GalleryItemLink 
                                Gallery_classname="gallery_container tawau"
                                Gallery_imgsrc={location_tawau}
                                Gallery_imgalttext="Tawau - Elysium Plantation"
                                Gallery_title="Tawau"
                                Gallery_link="/our-business/tawau-gallery"
                            />
                            <GalleryItemLink 
                                Gallery_classname="gallery_container jakarta"
                                Gallery_imgsrc={location_jakarta}
                                Gallery_imgalttext="Jakarta - Elysium Plantation"
                                Gallery_title="Jakarta"
                                Gallery_link="/our-business/jakarta-gallery"
                            />
                            <GalleryItemLink 
                                Gallery_classname="gallery_container siput"
                                Gallery_imgsrc={location_siput}
                                Gallery_imgalttext="Sungai Siput - Elysium Plantation"
                                Gallery_title="Sungai Siput"
                                Gallery_link="/our-business/sungai-siput-gallery"
                            />
                            <GalleryItemLink 
                                Gallery_classname="gallery_container mantakap"
                                Gallery_imgsrc={location_siput}
                                Gallery_imgalttext="Mantakap - Elysium Plantation"
                                Gallery_title="Mantakap"
                                Gallery_link="/our-business/mantakap-gallery"
                            />
                            <GalleryItemLink 
                                Gallery_classname="gallery_container desaru"
                                Gallery_imgsrc={location_siput}
                                Gallery_imgalttext="Desaru - Elysium Plantation"
                                Gallery_title="Desaru"
                                Gallery_link="/our-business/desaru-gallery"
                            />
                            <GalleryItemLink 
                                Gallery_classname="gallery_container ibg"
                                Gallery_imgsrc={location_siput}
                                Gallery_imgalttext="IBG Fertilizer - Elysium Plantation"
                                Gallery_title="IBG Fertilizer"
                                Gallery_link="/our-business/ibg-fertilizer-gallery"
                            />
                        </div>
                        
                    </section>
                </div>
                
            </div>
        </>
        
        
    );
}

export default OurBusinessPage;