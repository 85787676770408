import React, { useState } from 'react';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
import './Navbar.scss';
import '../../App.css';

import { Offcanvas, Accordion } from 'react-bootstrap';
// import { GiHamburgerMenu } from "react-icons/gi";
import { HiMenuAlt3 } from "react-icons/hi";

const NavLink = styled(Link)`
  &.active {
    color: #35893E;
    border-bottom: 3px solid #35893E;
  }

  @media screen and (max-width: 960px) {
    &.active {
        color: #35893E;
      }
    }

`;


const Navbar = () => {
    const [click, setClick] = useState(false);
    const [show, setShow] = useState(false);

    // Navigation
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const closeMobileMenu = () => setClick(false);

    return (
        <>

            <nav className="navbar">

                {/* <NavMenu > */}


                <div className="navbar-container">
                    
                    <div className="logo-container">
                        <a href="/home/" className="navbar-logo"  target='_top'>
                            <img src="/logo-06.png" alt="Elysium Plantation"/>
                        </a>
                    </div>
                            
                    {/* <ul className={click ? 'nav-menu active' : 'nav-menu'}> */}
                    <ul className='nav-menu'>
                        <li className='nav-item nav-dropdown'>
                            <NavLink to='/home/' className='nav-links' target='_top' onClick={closeMobileMenu}>
                                Home 
                            </NavLink>
                            <div className="nav-dropdown-content">
                                <a href="/home/#vision-and-mission" onClick={closeMobileMenu} target='_top'>Vision & Mission </a>
                                    <div className="dropdown-linediv"></div>
                                <a href="/home/#about-us" onClick={closeMobileMenu} target='_top'>About Us </a>
                                    <div className="dropdown-linediv"></div>
                                <a href="/home/#useful-links" onClick={closeMobileMenu} target='_top'>Useful Links </a>
                            </div>
                        </li>

                        <li className='nav-item nav-dropdown'>
                            <NavLink to='/corporate-info/' className='nav-links' target="_top"  onClick={closeMobileMenu}>
                                Corporate Info
                            </NavLink>
                            <div className="nav-dropdown-content nav-corporate-dd">
                                <a href="/corporate-info/#board-of-directors" target='_self'>Board of directors </a>
                                    <div className="dropdown-linediv"></div>
                                <a href="/corporate-info/#corporate-directory" target='_self'>Corporate Directory </a>
                                    <div className="dropdown-linediv"></div>
                                <a href="/corporate-info/#corporate-offices" target='_self'>Corporate Offices </a>
                                    <div className="dropdown-linediv"></div>
                                <a href="/corporate-info/#corporate-trustee" target='_self'>Corporate Trustee </a>
                                    <div className="dropdown-linediv"></div>
                                <a href="/corporate-info/#corporate-structure" target='_self'>Corporate Structure </a>
                            </div>
                        </li>

                        <li className='nav-item nav-dropdown'>
                            <NavLink to='/our-business/' className='nav-links' target="_top" onClick={closeMobileMenu} >
                                Our Business
                            </NavLink>

                            <div className="nav-dropdown-content">
                                <a href="/our-business/#core-business" onClick={closeMobileMenu} target='_self'>Core Business </a>
                                    <div className="dropdown-linediv"></div>
                                <a href="/our-business/#locations" onClick={closeMobileMenu} target='_self'>Locations </a>
                                    <div className="dropdown-linediv"></div>
                                <a href="/our-business/#photo-gallery" onClick={closeMobileMenu} target='_self'>Photo Gallery </a>
                            </div>
                        </li>

                        <li className='nav-item'>
                            <NavLink to='/company-policies/' className='nav-links' target="_top"  onClick={closeMobileMenu}>
                                Company Policies
                            </NavLink>
                        </li>

                        <li className='nav-item'>
                            <NavLink to='/news-and-events/' className='nav-links' target="_top" onClick={closeMobileMenu} >
                                News & Events
                            </NavLink>
                        </li>
                        
                        <li className='nav-item'>
                            <NavLink to='/contact-us/' className='nav-links' target="_top" onClick={closeMobileMenu} >
                                Contact Us
                            </NavLink>
                        </li>
                    </ul>

 
                    <div className='nav-menu-icon' onClick={handleShow}> 
                        <HiMenuAlt3 />
                        {/* {click ? <BsFillGridFill /> : <GiHamburgerMenu />} */}
                        {/* <i className={click ? 'fas fa-times' : 'fas fa-bars'} /> */}
                    </div>
                </div> {/* close navbar-container */}



                {/* Navbar mobile view  */}
                <Offcanvas show={show} onHide={handleClose} className="nav-canvas-mob">
                    <Offcanvas.Header closeButton style={{paddingTop: "1.5rem"}}>
                        <img src="/logo-06.png" width="200px" alt="Elysium Plantation logo"/>
                    </Offcanvas.Header>

                    <Offcanvas.Body>
                        <div className="offcanvas-content">
                            <Link to='/home' onClick={handleClose} target="_parent" >
                                <div className="offcanvas-menu-title">Home</div> 
                            </Link>
                                <div className="mobile-linediv"></div>
                            <Link to='/corporate-info' onClick={handleClose} target="_parent" >
                                <div className="offcanvas-menu-title">Corporate Info</div> 
                            </Link>
                                <div className="mobile-linediv"></div>
                            <Link to='/our-business' onClick={handleClose} target="_parent" >
                                <div className="offcanvas-menu-title">Our Business</div> 
                            </Link>
                                <div className="mobile-linediv"></div>
                            <Link to='/company-policies' onClick={handleClose} target="_parent" >
                                <div className="offcanvas-menu-title">Company Policies</div> 
                            </Link> 
                                <div className="mobile-linediv"></div>
                            <Link to='/news-and-events' onClick={handleClose} target="_parent" >
                                <div className="offcanvas-menu-title">News & Events</div> 
                            </Link>
                                <div className="mobile-linediv"></div>
                            <Link to='/contact-us' onClick={handleClose} target="_parent" >
                                <div className="offcanvas-menu-title">Contact Us</div> 
                            </Link>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
                
            </nav>
            
        </>

    )

};

export default Navbar;
