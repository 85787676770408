import React, { useState, useEffect } from 'react'; 
import '../../App.css';
import './PhotoGallery.scss';
  
import Modal from 'react-bootstrap/Modal';
import { Image } from 'antd';


function GalleryModal(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            
            <figure className="gallery__thumb"  data-toggle="modal" onClick={handleShow}>
                <img src={props.imgsrc} alt="Elysium Plantation's mantakap Location" className="gallery__image" />
            </figure>

             <Modal show={show} onHide={handleClose} className="gallery-modal" >
                <Modal.Body className="gallery-modal-body" >
                    <img src={props.imgsrc} alt="Elysium Plantation's mantakap Location" />
                </Modal.Body>
            </Modal>
        </>
    );
}

function MantakapGallery() {

    useEffect(() => {
        document.title = "Mantakap Gallery - Elysium Plantation Berhad ";  
    }, []);

    // mantakap
    const mantakap_1 = require("../../images/locations/Mantakap/mantakap-01.jpg");
    const mantakap_2 = require("../../images/locations/Mantakap/mantakap-02.jpg");
    const mantakap_3 = require("../../images/locations/Mantakap/mantakap-03.jpg");
    const mantakap_4 = require("../../images/locations/Mantakap/mantakap-04.jpg");
    const mantakap_5 = require("../../images/locations/Mantakap/mantakap-05.jpg");

    return (

        <>
            <div className="wrap-gallery">

                <section className='wrap-gallery-hero'>
                    <div className='gallery-hero'> 
                        <div className='gallery-hero-heading'>
                            <h1>Mantakap</h1>
                            <h2>Photo Gallery</h2>
                        </div>
                    </div>
                </section>

                <div className="ld-divider-right mobile-divd"></div>

                <div className="gallery-container">
                    <div className="gallery-content-1"> 
                        <Image.PreviewGroup>
                            <Image width={300} height={200} src={mantakap_1} alt="Mantakap - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={mantakap_2} alt="Mantakap - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={mantakap_3} alt="Mantakap - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={mantakap_4} alt="Mantakap - Elysium Plantation Berhad" />
                            <Image width={300} height={200} src={mantakap_5} alt="Mantakap - Elysium Plantation Berhad" />
                        </Image.PreviewGroup>
                    </div>
                </div>
                
            </div>
        </>
        
        
    );
}

export default MantakapGallery;