import React, {Fragment} from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';    /* react-router-dom version -> Switch (v5) = Routes (v6) */
import Navbar from './components/navigations/Navbar';
import Footer from './components/navigations/Footer';
import NotFoundPage from './components/navigations/NotFoundPage';
import Home from './components/pages/Home';
import CorporateInfo from './components/pages/Corporate_Info';
import OurBusiness from './components/pages/Our_Business';
import CompanyPolicies from './components/pages/Company_Policies';
import NewsEvents from './components/pages/News_Events';
import ContactUs from './components/pages/Contact_Us';

import KarakGallery from './components/photogallery/Karak_Gallery';
import ChemomoiGallery from './components/photogallery/Chemomoi_Gallery';
import KotaTinggiGallery from './components/photogallery/KotaTinggi_Gallery';
import TawauGallery from './components/photogallery/Tawau_Gallery';
import JakartaGallery from './components/photogallery/Jakarta_Gallery';
import SungaiSiputGallery from './components/photogallery/SungaiSiput_Gallery';
import MantakapGallery from './components/photogallery/Mantakap_Gallery';
import DesaruGallery from './components/photogallery/Desaru_Gallery';
import IBGFertilizerGallery from './components/photogallery/IBGFertilizer_Gallery';


function App() {

    return (
        <>
            <Router>

                <Navbar />  

                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/home" element={<Home />} />
                    <Route exact path="/vision-and-mission" element={<Home />} />
                    <Route exact path="/about-us" element={<Home />} />
                    <Route exact path="/useful-links" element={<Home />} />
                    <Route exact path="/corporate-info" element={<CorporateInfo />} />
                    <Route exact path="/our-business" element={<OurBusiness />} />
                    <Route exact path="/company-policies" element={<CompanyPolicies />} />
                    <Route exact path="/news-and-events" element={<NewsEvents />} />
                    <Route exact path="/contact-us" element={<ContactUs />} />
                    <Route exact path="/our-business/karak-gallery" element={<KarakGallery />} />
                    <Route exact path="/our-business/chemomoi-gallery" element={<ChemomoiGallery />} />
                    <Route exact path="/our-business/kota-tinggi-gallery" element={<KotaTinggiGallery />} />
                    <Route exact path="/our-business/tawau-gallery" element={<TawauGallery />} />
                    <Route exact path="/our-business/jakarta-gallery" element={<JakartaGallery />} />
                    <Route exact path="/our-business/sungai-siput-gallery" element={<SungaiSiputGallery />} />
                    <Route exact path="/our-business/mantakap-gallery" element={<MantakapGallery />} />
                    <Route exact path="/our-business/desaru-gallery" element={<DesaruGallery />} />
                    <Route exact path="/our-business/ibg-fertilizer-gallery" element={<IBGFertilizerGallery />} />
                
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>

                <Footer />
            </Router>
        
        </>
    );
}

export default App;
